<template>
  <div>
    <section v-if="this.totalRecords > 0">
      <b-row>
        <b-col>        
          <b-card>
            <b-card-title v-if="this.totalRecords == 1">Hai 1 Contratto da Accettare</b-card-title>
            <b-card-title v-else >Hai {{ this.totalRecords }} Contratti da Accettare</b-card-title>

            <!-- table -->
            <vue-good-table
            mode="remote"
            ref="table_Licenze_da_Attivare"
            @on-page-change="onPageChange_Licenzedaattivare"
            @on-per-page-change="onPerPageChange_Licenzedaattivare"
            :totalRows="totalRecords"
            :rows="rows"
            :columns="columns"
            :search-options="{ enabled: false, }"
            :pagination-options="{
                enabled: true,
                perPage:pageLength
            }"
            >
      
              <template
                  slot="table-row"
                  slot-scope="props"
              >
                  <!-- Column: Price -->
                  <span v-if="props.column.field === 'price'">
                    {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
                  </span>

                  <!-- Column: azioni -->
                  <span v-else-if="props.column.field === 'azioni'">
                    <div class="text-nowrap">
                      <feather-icon
                        icon="ArrowRightIcon"
                        class="cursor-pointer text-primary"
                        size="21"
                        @click="$router.push({ name: 'area-clienti-licenze-show', params: { id_riga: props.row.id }})"
                      />
                    </div>
                  </span>
      
                  <!-- Column: standard -->
                  <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                  </span>
              </template>
      
              <!-- pagination -->
              <template
                  slot="pagination-bottom"
                  slot-scope="props"
              >
                  <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                      Visualizzazione da 1 a 
                      </span>
                      <b-form-select
                      v-model="pageLength"
                      :options="['10','25','50','100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> di {{ props.total }} righe </span>
                  </div>
                  <div>
                      <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                      <template #prev-text>
                          <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                          />
                      </template>
                      <template #next-text>
                          <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                          />
                      </template>
                      </b-pagination>
                  </div>
                  </div>
              </template>
            </vue-good-table>

          </b-card>
        </b-col>
        
      </b-row>
      
    </section>

    <section v-if="this.userData.last_password_update	=== null">
      <b-row>
        <b-col>        
          <b-card>
            <b-card-title>E' necessario aggiornare la tua password di accesso</b-card-title>
            <b-button variant="primary" @click="$router.push({ name: 'area-clienti-profilo-cambio-password' })">Aggiorna Password</b-button>
          </b-card>
        </b-col>
        
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BCardTitle, BFormSelect, BPagination, BSpinner, VBTooltip, BButton } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { prezzoFormattato } from '@core/utils/funzioniGlobali' 

export default {
  components: {
    VueGoodTable,
    VBTooltip,
    BRow,
    BCol, 
    BCard, 
    BCardText,
    BCardTitle, 
    BFormSelect,
    BPagination, 
    BSpinner, 
    BButton, 

  },
  setup() {
      return {
          prezzoFormattato,
      }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      userData: {},
      
      emptystate: true, 
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Tipo Licenza',
          field: 'type',
        },
        {
          label: 'Codice',
          field: 'ref_code',
          width: '150px',
        },
        {
          label: 'Importo',
          field: 'price',
          width: '150px',
        },

        {
          label: 'Attivazione',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
          width: '150px',
        },
      ],
      rows: [],
      searchTerm: '',
      resetState: 'hidden',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          "status_id": {"operator": "<=", "value": 26 }, 
          "deleted_at": {"operator": "=", "value": null }
        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },

    }
  },
  created() {
    // dati reali
    this.userData = JSON.parse(localStorage.getItem('userData'))
    console.log(this.userData)

    //caricamento dei dati
    this.loadItems();

    this.Caricato = true;
    this.errore_caricamento = false;
  },
  methods: {
    updateParams(newProps) {  
      if (typeof newProps.columnFilters !== 'undefined'){
        //iniettare sempre il filtro per lo status
        newProps.columnFilters.deleted_at = {}
        newProps.columnFilters.deleted_at.operator = '='
        newProps.columnFilters.deleted_at.value = null
      }

      //attiva bottone "reset" filtro o ricerca
      this.resetState = 'attiva';

      this.serverParams = Object.assign({}, this.serverParams, newProps);
      
    },
    
    onPageChange_Licenzedaattivare(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },
    onPerPageChange_Licenzedaattivare(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    loadItems() {
      this.$http.get('api/customer/license/list', {
        params: this.serverParams 
        }).then(response => { 
            if(response.data.statusCode == 200){
              //ok
              this.totalRecords = response.data.reply.meta.total;
              this.rows = response.data.reply.data;
              
            } else if(response.data.statusCode == 422){
              //errori con testo di notifica ok dall'api
              this.$swal({
                title: 'Errore',
                text: 'codice errore: '+response.data.reply,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            } else {
              //tutto gli altri possibili errori
              this.$swal({
                title: 'Errore Generico',
                text: 'codice errore: '+response.data.statusCode,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            }
        
        })

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
