<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'home-agentscout' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Home
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
        
    <div class="text-center my-3" v-if="Caricato == false">
      <b-spinner  v-if="errore_caricamento == false"
          variant="primary"
          style="width: 3rem; height: 3rem;"
          label="loading"
      />
      <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
    </div>

    <b-card bg-variant="Default" v-if="Caricato">
      <b-row class="match-height">
        <b-col
          md="11"
          cols="10" 
          class="mb-2"
        >
          <!-- input search -->
          <b-input-group>
            <b-input-group-prepend>
              <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca.sezione" :options="options_ricerca" disabled-field="notEnabled"></b-form-select>
            </b-input-group-prepend>
              <b-form-input v-model="campiRicerca.nome"
                  placeholder="ricerca generale"
                  type="text" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click.prevent="ricercaGenerale">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>

          </b-input-group>
        </b-col>
        <b-col
          md="1"
          cols="2"
          class="mb-2"
        >
          <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset" block v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
        </b-col>
        
      </b-row>

        <!-- table -->
        <vue-good-table
        mode="remote"
        ref="table"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :rows="rows"
        :row-style-class="rowClassFunction"
        :columns="columns"
        :isLoading=isLoading
        :search-options="{ 
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        
        <template slot="loadingContent">
          <div class="text-center my-3">
              <b-spinner
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
              />
              <div class="d-block pt-1 text-primary">Caricamento</div>
          </div>
        </template>
        
        <div slot="emptystate"> <div class="text-center py-4"> Nessun dato disponibile - (Messaggio personalizzabile) </div> </div>

        <template
            slot="table-row"
            slot-scope="props"
        >

            <!-- Column: converted_at -->
            <div v-if="props.column.field === 'converted_at'">
              {{ formattazioneDataOra(props.row.converted_at, 'it') }}
            </div>

            <!-- Column: company_name -->
            <div v-else-if="props.column.field === 'billing.company_name'">
              <span v-if="props.row.billing">
                <a @click="$router.push({ name: 'crm-customers-show', params: { id_riga: props.row.id }})">{{ props.row.billing.company_name }}</a>
              </span>
              <span v-else>---</span>     
            </div>

            <!-- Column: stato -->
            <span v-else-if="props.column.field === 'status_id'">
            <b-badge :variant="statusVariant(props.row.status.value)">
              {{ props.row.status.text }}
            </b-badge>
            </span>

            <!-- Column: stato -->
            <span v-else-if="props.column.field === 'evaluation_id'">
            <b-badge :variant="statusVariantEvaluation(props.row.evaluation.value)">
              {{ props.row.evaluation.text }}
            </b-badge>
            </span>

            <!-- Column: azioni -->
            <span v-else-if="props.column.field === 'azioni'">
              <div class="text-nowrap">
                <feather-icon
                  icon="ArrowRightIcon"
                  class="cursor-pointer text-primary"
                  size="21"
                  v-b-tooltip.hover.v-primary
                  title="Azioni"
                  :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                  @click="sidebarAction(props.row.id, props.row.billing.company_name, props.row.user.name+' '+props.row.user.surname, props.row.user.email, props.row.user.mobile_phone, props.row.user.phone, props.row.source.text, props.row.billing.vat_number, props.row.note, props.row.rating, props.row.status.value)"
                />
              </div>
            </span>

            <!-- Column: standard -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Visualizzazione da 1 a 
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['10','25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> di {{ props.total }} righe </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
            
    </b-card>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar-right"
      bg-variant="white"
      :title="infoSideBar.titolo"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          
          <div class="divider">
              <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
          </div>
          <b-row cols="1">
            <b-col class="riga_sidebar" v-if="infoSideBar.nome">
              <span class="font-weight-bold">Referente:</span> {{ infoSideBar.nome }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.email">
              <span class="font-weight-bold">Email:</span> {{ infoSideBar.email }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.cellulare">
              <span class="font-weight-bold">Cellulare:</span> {{ infoSideBar.cellulare }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.fisso">
              <span class="font-weight-bold">Fisso:</span>  {{ infoSideBar.fisso }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.sorgente">
              <span class="font-weight-bold">Sorgente:</span>  {{ infoSideBar.sorgente }}
            </b-col>
            <b-col class="riga_sidebar" v-if="infoSideBar.rating">
              <span class="font-weight-bold">Rating:</span>  {{ infoSideBar.rating }}
            </b-col>
          </b-row>

          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="$router.push({ name: 'crm-customers-show', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="EyeIcon" /> Visualizza
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'crm-customers-edit', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="Edit2Icon" /> Modifica
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="recuperaDatiSocietari(infoSideBar.id,infoSideBar.titolo,infoSideBar.piva)"
          >
            <feather-icon icon="RefreshCwIcon" /> Aggiorna Dati Societari
          </b-button>

          <div v-if="infoSideBar.stato == 3">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary" block
              class="mb-1"
              @click="cambioStatoCustomer(infoSideBar.id, 2)"
            >
              <feather-icon icon="RefreshCcwIcon" /> Ripristina Clienti
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger" block
              class="mb-1"
              @click="cancellaRiga(infoSideBar.id)"
            >
              <feather-icon icon="Trash2Icon" /> Cancella definitivamente
            </b-button>
          </div>
          <div v-else>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger" block
              class="mb-1"
              @click="cambioStatoCustomer(infoSideBar.id, 3)"
            >
              <feather-icon icon="Trash2Icon" /> Cancella
            </b-button>
          </div>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            :href="`mailto:${infoSideBar.email}`"
          >
            <feather-icon icon="MailIcon" /> Invia Email
          </b-button>

          <newactivity field_tipo="Customer" :field_id="infoSideBar.id" :field_nome_relazione="infoSideBar.titolo"></newactivity>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="sidebarNuovaLicenza('Abbonamento',infoSideBar.id)"
          >
            <feather-icon icon="PlusIcon" /> Nuovo Abbonamento
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="sidebarNuovaLicenza('OneShot',infoSideBar.id)"
          >
            <feather-icon icon="PlusIcon" /> Nuovo One Shot
          </b-button>

        </div>

      </div>
    </b-sidebar>

    <b-modal
      ref="openapi-modal"
      size="lg"
      centered
      hide-footer
      title="Aggiorna Dati Societari"
      @hide="resetOpenapimodal"
    >
      <b-tabs content-class="pt-1" fill>
        <b-tab active>
          <template #title>
            <span>per Partita Iva</span> 
            <b-badge variant="success" style="margin-left: 10px; background-color: #009688;">Consigliata</b-badge>
          </template>
          
          <div v-if="this.controllo_piva_presente === false">
            <b-form>
              <b-form-group label-for="ricerca_piva">
                <b-form-input id="basicInput" placeholder="Partita Iva" v-model="campiformOpenApiRicercaPerPiva.piva" />
              </b-form-group>

              <b-button
                variant="primary"
                type="submit"
                :disabled="action_press_openApiRicercaPerPiva"
                @click.prevent="openApiRicercaPerPiva()"
              >
                <div v-if="action_press_openApiRicercaPerPiva">Ricerca in corso <b-spinner small class="ml-1" /></div>
                <div v-else>Recupera Dati</div> 
              </b-button>
            </b-form>
          </div>
          <div v-else>
            <div v-if="bottone_rimandare_scheda_cliente">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="$router.push({ name: 'crm-customers-show', params: { id_riga: dati_controllo_piva.userable_id }})"
                >
                  <feather-icon icon="EyeIcon" /> Visualizza Cliente
                </b-button>
              </p>
            </div>
            <div v-if="bottone_ripristino_scheda_cliente">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="ripristinaClienteDaSoftDelete(dati_controllo_piva.userable_id)"
                >
                  <feather-icon icon="RefreshCcwIcon" /> Ripristina Cliente
                </b-button>
              </p>
            </div>
          </div>

        </b-tab>
        <b-tab>
          <template #title>
            <span>per Ragione Sociale</span>
          </template>

          <div v-if="this.controllo_piva_presente_due === false">
            <b-form>
              <b-form-group label-for="ricerca_rag_sociale">
                <b-form-input id="basicInput" placeholder="Ragione Sociale" v-model="campiformOpenApiRicercaRagioneSociale.rag_sociale" />
              </b-form-group>

              <div v-if="open_api_ricerca_items" class="my-2">
                <b-overlay :show="isLoadingRecuperoDati" rounded="sm" spinner-small spinner-variant="primary">
                  <b-table striped :items="open_api_ricerca_items" :fields="open_api_ricerca_fields">
                    <template #cell(denominazione)="data">
                      {{ data.item.denominazione }}
                    </template>
                    <template #cell(id)="data">
                      <b-button @click="pippo(data.item.id)" variant="outline-primary">Recupera dati</b-button>
                    </template>
                  </b-table>
                </b-overlay>
              </div>

              <b-button
                variant="primary"
                type="submit"
                :disabled="action_press_openApiRicercaPerRagioneSociale"
                @click.prevent="openApiRicercaPerRagioneSociale()"
              >
                <div v-if="action_press_openApiRicercaPerRagioneSociale">Ricerca in corso <b-spinner small class="ml-1" /></div>
                <div v-else>Recerca Azienda</div> 
              </b-button>
            </b-form>
          </div>
          <div v-else>
            <div v-if="bottone_rimandare_scheda_cliente_due">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva_due.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="$router.push({ name: 'crm-customers-show', params: { id_riga: dati_controllo_piva_due.userable_id }})"
                >
                  <feather-icon icon="EyeIcon" /> Visualizza Cliente
                </b-button>
              </p>
            </div>
            <div v-if="bottone_ripristino_scheda_cliente_due">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva_due.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="ripristinaClienteDaSoftDelete(dati_controllo_piva_due.userable_id)"
                >
                  <feather-icon icon="RefreshCcwIcon" /> Ripristina Cliente
                </b-button>
              </p>
            </div>
          </div>
          
        </b-tab>
      </b-tabs>
      
    </b-modal>

    <b-sidebar
      ref="sidebar_nuova_licenza"
      id="sidebar-nuova-licenza"
      bg-variant="white"
      width="450px"
      title="Nuova Licenza"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          <div class="text-center my-3" v-if="Caricato_sidebar_nuova_licenza == false">
            <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
              class="mr-1"
              label="loading"
            />
          </div>
          <div v-else>
            <b-form-radio-group
              v-model="nuova_licenza_value"
              :options="nuova_licenza_options"
              :state="state_select_nuova_licenza"
              class="selezione_licenza"
              name="radio-validation"
              stacked
              html
            >
              <b-form-valid-feedback class="text-right my-2" :state="state_select_nuova_licenza">
                <b-button
                  variant="outline-primary"
                  type="submit"
                  @click.prevent="attivaNuovaLicenza(infoSideBarActionNuovaLicenza.tipo_licenza, infoSideBar.id)"
                  >
                  Inserisci nuova licenza
                </b-button>
              </b-form-valid-feedback>
            </b-form-radio-group>
          </div>
        </div>
      </div>
    </b-sidebar>

  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BForm, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip, BModal, BTabs, BTab, BTable, BOverlay, BFormRadioGroup, BFormValidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import newactivity from '@core/components/newactivity/newactivity.vue'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton, 
    BModal, 
    BTabs, 
    BTab, 
    BTable, 
    BOverlay,
    BFormRadioGroup,
    BFormValidFeedback,

    newactivity,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: true,
      emptystate: true, 
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Conversione',
          field: 'converted_at',
          width: '120px',
          filterOptions: {
            enabled: false,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Ragione Sociale',
          field: 'billing.company_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Cognome',
          field: 'user.surname',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Cellulare',
          field: 'user.mobile_phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Email',
          field: 'user.email',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Rating',
          field: 'rating',
          width: '80px',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
          },
        },
        {
          label: 'Valutazione',
          field: 'evaluation_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra', 
            filterDropdownItemsValu: [], 
          },
          //tdClass: 'd-none d-lg-table-cell',
        },
        {
          label: 'Stato',
          field: 'status_id',
          width: '145px',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra', 
            filterDropdownItems: [], 
          },
          //tdClass: 'd-none d-lg-table-cell',
        },

        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      resetState: 'hidden',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          "deleted_at": {"operator": "=", "value": null }

        },
        sort: [
            {
            field: 'converted_at',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams: {
        columnFilters: {
          "deleted_at": {"operator": "=", "value": null }
        },
        sort: [
            {
            field: 'converted_at',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca: [
        { value: null, text: 'seleziona...' },
        { value: 'billing.company_name', text: 'Ragione Sociale' },
        { value: 'user.name', text: 'Nome' },
        { value: 'user.surname', text: 'Cognome' },
        { value: 'user.email', text: 'Email' },
        { value: 'user.mobile_phone', text: 'Cellulare' },
        { value: 'rating', text: 'Rating (da 1 a 5)' },
        //{ value: 'source.id', text: 'Origine' },
        { value: 'billing.vat_number', text: 'P.iva' },
        /* 
        { value: 'roles.name', text: 'Ruolo' },
        { value: 'billing.region.name', text: 'Regione' },
        { value: 'billing.province.name', text: 'Provincia' },
        { value: 'colonna_inesistete', text: 'Colonna Errore' },
        { value: 'mobile_phone', text: 'Cellulare', notEnabled: true, disabled: true },
        {
          label: 'Raggruppamento',
          options: [
            { value: 'altro_1', text: 'Altro 1 (non usare)' },
            { value: 'altro_2', text: 'Altro 2 (non usare)' }
          ]
        }
        */
      ],
      campiRicerca: {
        nome: '',
        sezione: null,
      },
      infoSideBar: {
        id: '',
        fullName: '',
        email: '',
        mobile_phone: '',
        phone: '',
        lista_licenze: [ ], 
        piva: '', 
        note: '',
      },
      NuovaLicenzaSideBar: {
        id_riga: '',
      },
      listSourceOption: [ ],
      listStatusOption: [ ],
      listValutaionOption: [ ],
      Caricato: false,
      errore_caricamento: false,
      windowWidth: 0,

      openapiModal: {
        id_riga: '',
        rag_sociale: '',
        piva: '',

      },
      campiformOpenApiRicercaPerPiva: { 
        piva: '',
        id_riga: '',
        userable_type: 'Customer', //fisso per la sezione CUSTOMER del fronend vue (serve solo all'api per lavoro e non fare casino nelle operazioni richieste)
      },
      action_press_openApiRicercaPerPiva: false,
      campiformOpenApiRicercaRagioneSociale: { 
        rag_sociale: '',
        id_riga: '',
        userable_type: 'Customer', //fisso per la sezione CUSTOMER del fronend vue (serve solo all'api per lavoro e non fare casino nelle operazioni richieste)
      },
      action_press_openApiRicercaPerRagioneSociale: false,

      isLoadingRecuperoDati: false,
      open_api_ricerca_fields: ['denominazione', 'id'],
      open_api_ricerca_items: '',      

      controllo_piva_presente: false,
      bottone_rimandare_scheda_cliente: false,
      bottone_ripristino_scheda_cliente: false,
      dati_controllo_piva: {},
      controllo_piva_presente_due: false,
      bottone_rimandare_scheda_cliente_due: false,
      bottone_ripristino_scheda_cliente_due: false,
      dati_controllo_piva_due: {},

      Caricato_sidebar_nuova_licenza: false,
      nuova_licenza_value: null,
      nuova_licenza_options: [],
      infoSideBarActionNuovaLicenza: {
        tipo_licenza: '',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        2 : 'light-dark',
        3 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

    statusVariantEvaluation() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        2 : 'light-warning',
        3 : 'light-dark',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

    state_select_nuova_licenza() {
      return Boolean(this.nuova_licenza_value)
    },
  },
  mounted() {
    //in caso di resize del browser -> ricalcolo della larghezza della finestra
    window.onresize = () => {
      this.checkWindowsSizeAndResponsiveGoodTable();
    }
  },
  created() {
    const listSource = this.$http.get('api/fs/lead/source/list')
    const listStatus = this.$http.get('api/fs/customer/status/list')
    const listValutaion = this.$http.get('api/fs/customer/evaluation/list')

    this.checkWindowsSizeAndResponsiveGoodTable();
    
    Promise.all([listSource,listStatus,listValutaion]).then(response => {
      if(response[0].data.statusCode===200){

        //caricamento lista Source lead
        this.listSourceOption = response[0].data.reply

        if(response[1].data.statusCode===200){

          //caricamento lista Status
          this.listStatusOption = response[1].data.reply

          //popolazione select filtro "stato" lead
          this.columns[7].filterOptions.filterDropdownItems = response[1].data.reply

          if(response[2].data.statusCode===200){

            //caricamento lista Valutaion
            this.listValutaionOption = response[2].data.reply

            //popolazione select filtro "stato" lead
            this.columns[6].filterOptions.filterDropdownItems = response[2].data.reply

            //adesso faccio il caricamento dei dati dei leads
            this.loadItems();

            this.Caricato = true;
            this.errore_caricamento = false;

          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }

        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }

      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    })
  },
  methods: {
    checkWindowsSizeAndResponsiveGoodTable(){
      //nascondere colonna "Cognome" e "Rating" (non le posso cancellare perchè servano a far funzionare il resto della pagina)
      this.$set(this.columns[2], 'hidden', true)
      this.$set(this.columns[5], 'hidden', true)

    },
    rowClassFunction(row) {
      return row.status.value === 3 ? 'bg-danger bg-lighten-5' : ''; //rosso in caso di stato = Soft Delete
    },
    formattazioneDataOra(converted_at,lang) {
      if (converted_at) {
        return moment(String(converted_at)).locale(lang).format('DD/MM/YYYY')
      } else {
        return '---'
      }
    },
    ricercaGenerale(){
      console.log("ricerca generale - chiamata Api per avere la ricerca su tutti i dati e NON solo sui dati dell'attuale viewport della tabella ;-)")
      console.log(this.campiRicerca)

      if((this.campiRicerca.sezione !== null) && (this.campiRicerca.sezione !== 'undefined')){
        console.log("ok ricerca valida")
        let procedi = 1;

        //gestione ricerca per Origine
        if(this.campiRicerca.sezione == 'source.id'){
          let risultato = this.listSourceOption.find(item => item.text === this.campiRicerca.nome);

          if(risultato === undefined){
            procedi = 0; //STOP ricerca !!!

            this.$swal({
                icon: 'error',
                title: 'Errore nella ricerca',
                text: 'L\'origine ricercata non esiste, prego controllare il testo di ricerca inserito',
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

          } else {
            //console.log("trovato ("+this.campiRicerca.nome+") -> "+risultato.value)
            //console.log(risultato)
            procedi = 1;

            //aggiorna dati prima di inviarli all'updateParams
            this.campiRicerca.nome = risultato.value //ovvero invio ID dell'origine (source) ricercata
          }
        }

        //aggiorna parametri con la stessa funzione del filtro colonna ;-)
        if(procedi == 1){
          this.updateParams({
            columnFilters: {
              [this.campiRicerca.sezione]: this.campiRicerca.nome
            },
          });

          //forza reset filtro colonne
          this.$refs.table.reset();

          this.loadItems();

          //attiva bottone "reset"
          this.resetState = 'attiva';
        }
      }
    },
    clickReset(){
      console.log("clickReset");

      //forza reset filtro colonne
      this.$refs.table.reset();

      //forza reset parametri iniziali della tabella
      this.updateParams(this.startServerParams);
      this.loadItems();

      //reset campi ricerca
      this.campiRicerca.nome = '';
      this.campiRicerca.sezione = null;
      //disattiva bottone "reset"
      this.resetState = 'hidden';
    },
    advanceFilter(val) {
      //this.searchTerm = val

      this.updateParams(val);
      this.loadItems();
    },
    sidebarAction(id, azienda, nome_cognome, email, mobile_phone, phone, sorgente, vat_number, note, rating, stato) {
      this.infoSideBar = { id: id,
        titolo: azienda,
        nome: nome_cognome,
        email: email,
        cellulare: mobile_phone,
        fisso: phone,
        sorgente: sorgente, 
        piva: vat_number,  
        note: note, 
        rating: rating, 
        stato: stato, };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    
    updateParams(newProps) {
      //console.log(newProps)

      //attiva bottone "reset" filtro o ricerca
      this.resetState = 'attiva';

      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      console.log('cambio ordinamento')
      console.log(params)

      let field_ricerca = '';

      //colonne con relazione
      if(params[0].field == 'source.text'){
        field_ricerca = 'source.name';
      } else {
        field_ricerca = params[0].field;
      }

      this.updateParams({
        sort: [{
          type: params[0].type,
          field: field_ricerca,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      console.log("AGGIORNAMENTO FILTRO COLONNA --------")
      this.updateParams(params);
      this.loadItems();
    },

    loadItems() {
      this.isLoading = true;

      this.$http.get('api/crm/customer/list', {
        params: this.serverParams 
        }).then(response => { 
            if(response.data.statusCode == 200){
              //ok
              this.totalRecords = response.data.reply.meta.total;
              this.rows = response.data.reply.data;
              
            } else if(response.data.statusCode == 422){
              //errori con testo di notifica ok dall'api
              this.$swal({
                title: 'Errore',
                text: 'codice errore: '+response.data.reply,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            } else {
              //tutto gli altri possibili errori
              this.$swal({
                title: 'Errore Generico',
                text: 'codice errore: '+response.data.statusCode,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  textButton: 'Chiudi',
                },
                buttonsStyling: false,
              })
            }
            
            //rimuovi il loading dopo il caricamento dei dati nella tabella
            this.isLoading = false;
        
        })

    },
    cambioStatoCustomer(id_riga,id_stato){
      let messaggio='';
      let btn_color='';
      if(id_stato == 3){
        messaggio+="confermi la cancellazione del Cliente?";
        btn_color+='btn btn-outline-danger';
      } else {
        messaggio+="confermi il ripristino del Cliente?";
        btn_color+='btn btn-outline-primary';
      }

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: messaggio,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: btn_color,
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/customer/cambiostato/'+id_riga+'/'+id_stato)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.reply,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems();
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    cancellaRiga(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "confermi la cancellazione definitiva del Cliente?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/customer/delete/'+id_riga)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: response.data.reply.reply,
                    //text: 'operazione effettuata correttamente',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems();
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    recuperaDatiSocietari(id_riga,rag_sociale,piva){
      this.openapiModal = {
        id_riga: id_riga,
        rag_sociale: rag_sociale,
        piva: piva,
      }

      this.campiformOpenApiRicercaPerPiva.id_riga = id_riga;

      if((piva !== null) && (piva !== undefined) && (piva != '')){
        //auto completo il form per "partita iva" di ricerca sulla modale
        this.campiformOpenApiRicercaPerPiva.piva = piva;
      }

      this.campiformOpenApiRicercaRagioneSociale.id_riga = id_riga;

      if((rag_sociale !== null) && (rag_sociale !== undefined) && (rag_sociale != '')){
        //auto completo il form per "ragione sociale" di ricerca sulla modale
        this.campiformOpenApiRicercaRagioneSociale.rag_sociale = rag_sociale;
      }

      this.$refs['openapi-modal'].show()
    },
    resetOpenapimodal(){
      this.openapiModal = {
        id_riga: '',
        rag_sociale: '',
        piva: '',
      }

      //reset tutte i form della modale
      this.resetCampiRicercaOpenApiTutti();
    },
    openApiRicercaPerPiva(){
      //console.log('Si parte - openApiRicercaPerPiva()')
      //console.log(this.campiformOpenApiRicercaPerPiva)

      this.action_press_openApiRicercaPerPiva = true

      this.$http.post('api/crm/customer/openapi/ricerca/recuperadati', 
        this.campiformOpenApiRicercaPerPiva 
        ).then(response => { 
          console.log("risposta server --------")
          console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva dal server
            this.action_press_openApiRicercaPerPiva = false;

            if(response.data.reply.controllo_piva_presente === true){
              //Risposta dal controllo della P.Iva già presente su un cliente
              this.controllo_piva_presente = true;
              this.dati_controllo_piva = response.data.reply;

              if(response.data.reply.deleted_at	=== null){
                //solo bottone per rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente = true;
                this.bottone_ripristino_scheda_cliente = false;
              } else {
                //bottone per ripristino cliente e poi rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente = false;
                this.bottone_ripristino_scheda_cliente = true;
              }

            } else {
              //Risposta alle operazioni di recupero dati da OpenApi

              //esito negativo da OpenApi
              if(response.data.reply.status == 'errore'){
                //notifica errore
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.messaggio,
                    customClass: {
                    confirmButton: 'btn btn-danger',
                    },
                })

              } else {
                //esito ok da OpenApi
                this.$router.replace('/crm/leads/show/'+this.campiformOpenApiRicercaPerPiva.id_riga)
                .then(() => {
                  this.$swal({
                      icon: 'success',
                      title: 'Dati sociatari e bilanci aggiornati',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                })

              }

            }
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_openApiRicercaPerPiva = false;
          }
        }).catch(e => {
          console.log(e);
        });

    },
    ripristinaClienteDaSoftDelete(userable_id){
      console.log("ripristinaClienteDaSoftDelete -> " + userable_id);

      this.$http.post('api/crm/customer/ricovery/' + userable_id).then(response => { 
          console.log("risposta server 'ricovery' --------")
          //console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            
            //redirect con messaggio specifico
            this.$router.replace('/crm/customers/show/' + userable_id)
            .then(() => {
              this.$swal({
                  icon: 'success',
                  title: 'Cliente ripristinato correttamente',
                  confirmButtonText: 'chiudi',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
              })
            })
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_openApiRicercaPerRagioneSociale = false;
          }
        }).catch(e => {
          console.log(e);
        });
    },
    openApiRicercaPerRagioneSociale(){
      //console.log('Si parte - openApiRicercaPerRagioneSociale()')
      //console.log(this.campiformOpenApiRicercaRagioneSociale)

      this.action_press_openApiRicercaPerRagioneSociale = true

      this.$http.post('api/crm/customer/openapi/ricerca/ragionisociali', 
        this.campiformOpenApiRicercaRagioneSociale 
        ).then(response => { 
          //console.log("risposta server --------")
          //console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.action_press_openApiRicercaPerRagioneSociale = false;

            console.log(response.data.reply.dati.data)
            this.open_api_ricerca_items = response.data.reply.dati.data;
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_openApiRicercaPerRagioneSociale = false;
          }
        }).catch(e => {
          console.log(e);
        });
    },
    resetCampiRicercaOpenApiTutti(){
      this.campiformOpenApiRicercaPerPiva = {
        piva: '',
        id_riga: '',
      }
      this.campiformOpenApiRicercaRagioneSociale = {
        rag_sociale: '',
        id_riga: '',
      }

      //eventuale ricerca ragioni sociali
      this.open_api_ricerca_items = '';
    },
    pippo(id) {
      // Implementa la tua funzione qui
      console.log('ID:', id);
      console.log(this.campiformOpenApiRicercaRagioneSociale)

      this.isLoadingRecuperoDati = true;

      this.$http.post('api/crm/customer/openapi/ricerca/recuperadati', 
          { 
            piva: id, //codice openapi per il recupero dei dati dell'azienda
            id_riga: this.campiformOpenApiRicercaRagioneSociale.id_riga,
            userable_type: 'Lead',
          }
        ).then(response => { 
          console.log("risposta server --------")
          console.log(response.data)

          /*
          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.isLoadingRecuperoDati = false;

            //esito negativo da OpenApi
            if(response.data.reply.status == 'errore'){
              //notifica errore
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore',
                  text: response.data.reply.messaggio,
                  customClass: {
                  confirmButton: 'btn btn-danger',
                  },
              })

            } else {
              //esito ok da OpenApi
              this.$router.replace('/crm/leads/show/'+this.campiformOpenApiRicercaRagioneSociale.id_riga)
              .then(() => {
                this.$swal({
                    icon: 'success',
                    title: 'Dati sociatari e bilanci aggiornati',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })
              })

            }
            
          }
          */

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva dal server
            this.isLoadingRecuperoDati = false;

            if(response.data.reply.controllo_piva_presente === true){
              //Risposta dal controllo della P.Iva già presente su un cliente
              this.controllo_piva_presente_due = true;
              this.dati_controllo_piva_due = response.data.reply;

              if(response.data.reply.deleted_at	=== null){
                //solo bottone per rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente_due = true;
                this.bottone_ripristino_scheda_cliente_due = false;
              } else {
                //bottone per ripristino cliente e poi rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente_due = false;
                this.bottone_ripristino_scheda_cliente_due = true;
              }

            } else {
              //Risposta alle operazioni di recupero dati da OpenApi

              //esito negativo da OpenApi
              if(response.data.reply.status == 'errore'){
                //notifica errore
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.messaggio,
                    customClass: {
                    confirmButton: 'btn btn-danger',
                    },
                })

              } else {
                //esito ok da OpenApi
                this.$router.replace('/crm/leads/show/'+this.campiformOpenApiRicercaRagioneSociale.id_riga)
                .then(() => {
                  this.$swal({
                      icon: 'success',
                      title: 'Dati sociatari e bilanci aggiornati',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                })

              }

            }
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.isLoadingRecuperoDati = false;
          }




        }).catch(e => {
          console.log(e);
        });

    },
    
    sidebarNuovaLicenza(tipo,id_riga) {
      let route = '';
      if(tipo == 'Abbonamento'){
        route = 'subscription/';
      } else {
        route = 'oneshot/';
      }

      this.infoSideBarActionNuovaLicenza = { 
        tipo_licenza: tipo
      };

      //reimposta loader per caricamento licenze da stripe
      this.Caricato_sidebar_nuova_licenza = false

      //cancella lista licenza di stripe eventualmente caricate in precedenza
      this.nuova_licenza_options = [];

      this.NuovaLicenzaSideBarSideBar = { id_riga: id_riga };

      //evento apri sidebar
      this.$root.$emit('bv::toggle::collapse', 'sidebar-nuova-licenza')

      this.$http.get('api/crm/stripe/product/'+route+'list').then(response => { 
        if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
          
          
          console.log("dati prodotti su Stripe")
          console.log(response.data.reply)
          console.log("lunghezza array -> "+response.data.reply.length)
          
          
          //caricamento lista licenze per corretta visualizzazione
          let u = 0;
          while (u < response.data.reply.length) {
            let option = response.data.reply[u].name;
            
            this.nuova_licenza_options[u] = { 
              html: option,
              value: response.data.reply[u].default_price.id+'----'+response.data.reply[u].id
            },            
            u++;
          }

          //console.log(this.nuova_licenza_options)

          this.Caricato_sidebar_nuova_licenza = true

        } else {
          //risposta negativa (errore sul server)
          if(response.data.reply.message != ''){
            this.$swal({
                icon: 'warning',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })
          } else {
            this.$swal({
                icon: 'error',
                title: 'Impossibile recuperare i dati dei prodotti su Stripe',
                text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })
          }

          this.Caricato_sidebar_nuova_licenza = false
        }

      }).catch(e => {
        console.log(e);
      });
    },
    attivaNuovaLicenza(tipo,id_riga){
      /*
      console.log("attivaNuovaLicenza ------------")
      console.log("id_licenza selezionata -> "+this.nuova_licenza_value)
      console.log("va inviata all'api come variabile 'price_id' + il valore di customer_id come 'customer_id'")
      */
     
      //split dei dati di stripe
      const dati_stripe = this.nuova_licenza_value.split('----');

      this.$http.post('api/crm/license/card', {
        price_id: dati_stripe[0], 
        product_id: dati_stripe[1], 
        customer_id: id_riga
      }).then(response_nuova_licenza => { 
        //chiudi sidebar
        this.$refs.sidebar_nuova_licenza.hide();

        if((response_nuova_licenza.data.statusCode>=200) && (response_nuova_licenza.data.statusCode<=299)){
          //salvataggio effettuato
          console.log(response_nuova_licenza.data)

          this.$swal({
              icon: 'success',
              title: 'Licenza inserita correttamente',
              //text: 'operazione effettuata correttamente',
              confirmButtonText: 'chiudi',
              customClass: {
              confirmButton: 'btn btn-success',
              },
          })
          
        } else {
          //risposta negativa (errore sul server)
          if(response_nuova_licenza.data.reply.message != ''){
            this.$swal({
              icon: 'warning',
              title: 'Si è verificato un errore',
              text: response_nuova_licenza.data.reply.message,
              customClass: {
              confirmButton: 'btn btn-outline-primary',
              },
            })
          } else {
            this.$swal({
              icon: 'error',
              title: 'Impossibile attivare la nuova licenza per questo cliente',
              text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
              customClass: {
              confirmButton: 'btn btn-outline-primary',
              },
            })
          }
        }
      }).catch(e => {
        this.$swal({
          icon: 'error',
          title: 'Impossibile salvare la nuova licenza per questo cliente',
          text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
          customClass: {
          confirmButton: 'btn btn-outline-primary',
          },
        })
      });

    },
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.riga_sidebar{
  padding-bottom: 5px;
}

.selezione_licenza{
  margin-top: 25px;
}
.selezione_licenza .custom-radio.custom-control{
  margin-bottom: 15px;
  padding-bottom: 14px;
  border-bottom: 1px solid #ebe9f1;
}
.selezione_licenza .custom-radio.custom-control .custom-control-label{
  width: 100%;
}
</style>