<template>
    <div>
        <b-card bg-variant="Success" class="text-center" v-if="submitted">
            <b-button
            variant="outline-primary"
            :to="{ name: 'tools-emailtemplate-index' }"
            >
                <feather-icon
                    icon="CornerUpLeftIcon"
                    size="16"
                    class="mr-1"
                />Lista email template
            </b-button>
        </b-card>

        <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
            <b-col
              md="8"
            >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
            </b-col>
            <b-col 
              md="4"
              class="text-center"
            >
              <b-button
              variant="primary"
              :to="{ name: 'tools-emailtemplate-index' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Lista email template
              </b-button>
            </b-col>
          </b-row>
            
        </b-card>

        <div class="text-center my-3" v-if="Caricato == false">
            <b-spinner  v-if="errore_caricamento == false"
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
            />
            <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-if="Caricato">
          <validation-observer ref="simpleRules">
            <b-form>
                <b-tabs pills>
                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="SendIcon"
                            size="16"
                            class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Opzioni Template</span>
                        </template>
                        <b-card bg-variant="Default">
                            <b-row>
                            <b-col lg="6">
                                <b-form-group label="Nome template *" label-for="name">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Nome del template"
                                    rules="required"
                                    >
                                    <b-form-input
                                        v-model="campiform.name"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder=""
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6">
                                <b-form-group label="Descrizione del template" label-for="description">
                                    <b-form-input
                                        v-model="campiform.description"
                                        placeholder=""
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col lg="6">
                                <b-form-group label="Oggetto email*" label-for="subject">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Oggetto dell'email template"
                                    rules="required"
                                    >
                                    <b-form-input
                                        v-model="campiform.subject"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder=""
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="6">
                              <b-form-group label="Preheader" label-for="preheader">
                                    <b-form-input
                                        v-model="campiform.preheader"
                                        placeholder=""
                                    />
                                </b-form-group>
                            </b-col>
                            
                            <b-col lg="6">
                                <b-form-group label="Categoria Template *" label-for="category_id">
                                    <b-form-select
                                        v-model="campiform.category_id"
                                        :options="options_categorie"
                                    />
                                </b-form-group>
                            </b-col>
                            
                            <b-col lg="6">
                                <b-form-group label="Mittente" label-for="mittente">
                                    <b-form-input
                                        v-model="campiform.sender"
                                        placeholder=""
                                    />
                                </b-form-group>
                            </b-col>
                            
                            </b-row>

                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="MailIcon"
                            size="16"
                            class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Contenuto Template</span>
                        </template>

                        <b-card bg-variant="Default">
                          <quill-editor
                            v-model="campiform.text"
                            :options="snowOption"
                          >
                            <div
                              id="toolbar"
                              slot="toolbar"
                            >
                              <button class="ql-bold">Bold</button>
                              <button class="ql-italic">Italic</button>
                              <button class="ql-underline">Underline</button>
                              <button class="ql-strike mr-2">Strike</button>

                              <select class="ql-header">
                                <option value="1" />
                                <option value="2" />
                                <option value="3" />
                                <option value="4" />
                                <option value="4" />
                                <option value="5" />
                                <option value="6" />
                                <option value="" selected />
                              </select>
                              <select class="ql-size mr-2">
                                <option value="small" />
                                <!-- Note a missing, thus falsy value, is used to reset to default -->
                                <option selected />
                                <option value="large" />
                                <option value="huge" />
                              </select>

                              <button class="ql-list" value="ordered">list ordered</button>
                              <button class="ql-list" value="bullet">list bullet</button>
                              <select class="ql-align mr-2" />

                              <button class="ql-link">Link</button>
                              <button class="ql-image mr-2">Image</button>

                              <button class="ql-clean mr-2">clean</button>

                              <!-- custom placeholder Pachetti -->
                              <b-dropdown
                                class="mx-1 btn-pacchetti"                                
                                text="Pacchetti"                                
                              >
                                <b-dropdown-item @click.prevent="customButtonClick('pacchetto-1')">Pacchetto 1</b-dropdown-item>
                                <b-dropdown-item @click.prevent="customButtonClick('pacchetto-due')">Pacchetto due</b-dropdown-item>
                                <b-dropdown-item @click.prevent="customButtonClick('pacchetto-3')">Pacchetto 3</b-dropdown-item>
                              </b-dropdown>
                            </div>

                          </quill-editor>


                            
                        </b-card>
                    </b-tab>
                </b-tabs>

                <b-row>
                    <b-col md="12" class="text-center">
                    <b-button
                        variant="primary"
                        type="submit"
                        :disabled="action_press"
                        @click.prevent="validationForm()"
                    >
                        <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                        <div v-else>Salva</div> 
                    </b-button>
                    </b-col>
                </b-row>
              
            </b-form>
          </validation-observer>
        </div>
          
    </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, 
    BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdown, BDropdownItem, BFormSelect } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import {
  required, email, digits, alphaDash, length, password,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs, 
    BTab, 
    BRow,
    BCol,
    BCard, 
    BSpinner, 
    BCardText, 
    BButton, 
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormSelect,

    quillEditor,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,     
  },
  data() {
    return {
      campiform: {
        name: '',
        description: '',
        sender: '',
        subject: '',
        preheader: '',
        text: ``,
        category_id: '',
        active: false
      },
      required,
      email,
      password, 
      alphaDash, 
      digits,
      length,
      options_categorie: [ ],
      Caricato: false,
      errore_caricamento: false,
      submitted: false,
      action_press: false,
      
      snowOption: {
        modules: {
          toolbar: '#toolbar',
        },
        theme: 'snow',
      },

    }
  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    const categoriesPromise = this.$http.get('api/crm/emailtemplate/catlist')    
    const infoEmailtemplatePromise = this.$http.get('api/crm/emailtemplate/card/'+router.currentRoute.params.id_riga)

    Promise.all([categoriesPromise, infoEmailtemplatePromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista categorie
        this.options_categorie = response[0].data.reply

        if(response[1].data.statusCode===200){
            //form dati email template
            //console.log(response[1].data.reply.data)
            this.campiform = response[1].data.reply.data

            //fine
            this.Caricato = true;
            this.errore_caricamento = false;
        } else {
            this.Caricato = false;
            this.errore_caricamento = true;
        }

      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
        
    })
  },
  methods: {
    customButtonClick(placeholder_pacchetto) {
      this.campiform.text = this.campiform.text+' {{'+placeholder_pacchetto+'}}';
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //console.log(this.campiform)
          this.action_press = true
          
          this.$http.post('api/crm/emailtemplate/card', 
            this.campiform
            ).then(response => { 
              //console.log("risposta server --------")
              //console.log(response.data)

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                this.$router.replace('/tools/emailtemplate')
                .then(() => {
                    this.$swal({
                        icon: 'success',
                        title: 'Email template aggiornato correttamente',
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })
                })                    

            } else {
                //risposta negativa (errore sul server)
                if(response.data.reply.message != ''){
                this.$swal({
                    icon: 'warning',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.message,
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
                } else {
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore generico',
                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
                }

                this.action_press = false
            }
            }).catch(e => {
              console.log(e);
            });
          
          
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/quill.scss';

.btn-pacchetti{
  width: 120px;
}
</style>
