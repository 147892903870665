<template>
    <div>
        <b-card bg-variant="Success" class="text-center" v-if="submitted">
            <b-button
            variant="outline-primary"
            :to="{ name: 'tools-contracttemplate-index' }"
            >
                <feather-icon
                    icon="CornerUpLeftIcon"
                    size="16"
                    class="mr-1"
                />Lista contratti template
            </b-button>
        </b-card>

        <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
            <b-col
              md="8"
            >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
            </b-col>
            <b-col 
              md="4"
              class="text-center"
            >
              <b-button
              variant="primary"
              :to="{ name: 'tools-contracttemplate-index' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Lista contratti template
              </b-button>
            </b-col>
          </b-row>
            
        </b-card>

        <div class="text-center my-3" v-if="Caricato == false">
            <b-spinner  v-if="errore_caricamento == false"
                variant="primary"
                style="width: 3rem; height: 3rem;"
                label="loading"
            />
            <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
        </div>

        <div v-if="Caricato">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-tabs pills>
                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="FileTextIcon"
                            size="16"
                            class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Opzioni Contratto</span>
                        </template>
                        <b-card bg-variant="Default">
                            <b-row>
                            <b-col lg="6">
                                <b-form-group label="Nome template *" label-for="name">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Nome del template"
                                    rules="required"
                                    >
                                    <b-form-input
                                        v-model="campiform.nome"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder=""
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6">
                                <b-form-group label="Descrizione del template" label-for="description">
                                    <b-form-input
                                        v-model="campiform.descrizione"
                                        placeholder=""
                                    />
                                </b-form-group>
                            </b-col>
                            
                            <b-col lg="6">
                                <b-form-group label="Seleziona Licenza *" label-for="category_id">
                                    <validation-provider
                                    #default="{ errors }"
                                    name="Categoria template"
                                    rules="required"
                                    >
                                      <b-form-select
                                          v-model="campiform.id_prod_stripe"
                                          :options="options_licenze_stripe"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            
                            </b-row>

                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="FileTextIcon"
                            size="16"
                            class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Contenuto Modulo d'Ordine</span>
                        </template>

                        <b-card bg-variant="Default">
                          <b-form-group label="Modulo d'Ordine*" label-for="modulo_ordine">
                            <validation-provider
                            #default="{ errors }"
                            name="Modulo d'Ordine"
                            rules="required"
                            >
                            <quill-editor
                              v-model="campiform.modulo_ordine"
                              :options="snowOption"
                              class="custom-editor"
                            >
                              <div
                                id="toolbar"
                                slot="toolbar"
                              >
                                <button class="ql-bold">Bold</button>
                                <button class="ql-italic">Italic</button>
                                <button class="ql-underline">Underline</button>
                                <button class="ql-strike mr-2">Strike</button>

                                <select class="ql-header">
                                  <option value="1" />
                                  <option value="2" />
                                  <option value="3" />
                                  <option value="4" />
                                  <option value="4" />
                                  <option value="5" />
                                  <option value="6" />
                                  <option value="" selected />
                                </select>
                                <select class="ql-size mr-2">
                                  <option value="small" />
                                  <!-- Note a missing, thus falsy value, is used to reset to default -->
                                  <option selected />
                                  <option value="large" />
                                  <option value="huge" />
                                </select>

                                <button class="ql-list" value="ordered">list ordered</button>
                                <button class="ql-list" value="bullet">list bullet</button>
                                <select class="ql-align mr-2" />

                                <button class="ql-link">Link</button>
                                <button class="ql-image mr-2">Image</button>

                                <button class="ql-clean mr-2">clean</button>
                              </div>

                            </quill-editor>
                            
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="FileTextIcon"
                            size="16"
                            class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Contenuto Termini e Condizioni</span>
                        </template>

                        <b-card bg-variant="Default">
                          <b-form-group label="Termini e Condizioni*" label-for="description">
                            <validation-provider
                            #default="{ errors }"
                            name="Termini e Condizioni"
                            rules="required"
                            >
                            <quill-editor
                              v-model="campiform.termini_e_condizioni"
                              :options="snowOption_due"
                              class="custom-editor mb-3"
                            >
                              <div
                                id="toolbar_due"
                                slot="toolbar"
                              >
                                <button class="ql-bold">Bold</button>
                                <button class="ql-italic">Italic</button>
                                <button class="ql-underline">Underline</button>
                                <button class="ql-strike mr-2">Strike</button>

                                <select class="ql-header">
                                  <option value="1" />
                                  <option value="2" />
                                  <option value="3" />
                                  <option value="4" />
                                  <option value="4" />
                                  <option value="5" />
                                  <option value="6" />
                                  <option value="" selected />
                                </select>
                                <select class="ql-size mr-2">
                                  <option value="small" />
                                  <!-- Note a missing, thus falsy value, is used to reset to default -->
                                  <option selected />
                                  <option value="large" />
                                  <option value="huge" />
                                </select>

                                <button class="ql-list" value="ordered">list ordered</button>
                                <button class="ql-list" value="bullet">list bullet</button>
                                <select class="ql-align mr-2" />

                                <button class="ql-link">Link</button>
                                <button class="ql-image mr-2">Image</button>

                                <button class="ql-clean mr-2">clean</button>
                              </div>

                            </quill-editor>
                            
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                          <b-form-group label="Clausole vessatorie*" label-for="clausole_vessatorie">
                            <validation-provider
                            #default="{ errors }"
                            name="Clausole vessatorie"
                            rules="required"
                            >
                            <quill-editor
                              v-model="campiform.clausole_vessatorie"
                              :options="snowOption_tre"
                              class="custom-editor"
                            >
                              <div
                                id="toolbar_tre"
                                slot="toolbar"
                              >
                                <button class="ql-bold">Bold</button>
                                <button class="ql-italic">Italic</button>
                                <button class="ql-underline">Underline</button>
                                <button class="ql-strike mr-2">Strike</button>

                                <select class="ql-header">
                                  <option value="1" />
                                  <option value="2" />
                                  <option value="3" />
                                  <option value="4" />
                                  <option value="4" />
                                  <option value="5" />
                                  <option value="6" />
                                  <option value="" selected />
                                </select>
                                <select class="ql-size mr-2">
                                  <option value="small" />
                                  <!-- Note a missing, thus falsy value, is used to reset to default -->
                                  <option selected />
                                  <option value="large" />
                                  <option value="huge" />
                                </select>

                                <button class="ql-list" value="ordered">list ordered</button>
                                <button class="ql-list" value="bullet">list bullet</button>
                                <select class="ql-align mr-2" />

                                <button class="ql-link">Link</button>
                                <button class="ql-image mr-2">Image</button>

                                <button class="ql-clean mr-2">clean</button>
                              </div>

                            </quill-editor>
                            
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="FileTextIcon"
                            size="16"
                            class="mr-0 mr-sm-50"
                            />
                            <span class="d-none d-sm-inline">Contenuto Privacy Policy</span>
                        </template>

                        <b-card bg-variant="Default">
                          <b-form-group label="Privacy policy*" label-for="privacy_policy">
                            <validation-provider
                            #default="{ errors }"
                            name="Privacy policy"
                            rules="required"
                            >
                            <quill-editor
                              v-model="campiform.privacy_policy"
                              :options="snowOption_quattro"
                              class="custom-editor"
                            >
                              <div
                                id="toolbar_quattro"
                                slot="toolbar"
                              >
                                <button class="ql-bold">Bold</button>
                                <button class="ql-italic">Italic</button>
                                <button class="ql-underline">Underline</button>
                                <button class="ql-strike mr-2">Strike</button>

                                <select class="ql-header">
                                  <option value="1" />
                                  <option value="2" />
                                  <option value="3" />
                                  <option value="4" />
                                  <option value="4" />
                                  <option value="5" />
                                  <option value="6" />
                                  <option value="" selected />
                                </select>
                                <select class="ql-size mr-2">
                                  <option value="small" />
                                  <!-- Note a missing, thus falsy value, is used to reset to default -->
                                  <option selected />
                                  <option value="large" />
                                  <option value="huge" />
                                </select>

                                <button class="ql-list" value="ordered">list ordered</button>
                                <button class="ql-list" value="bullet">list bullet</button>
                                <select class="ql-align mr-2" />

                                <button class="ql-link">Link</button>
                                <button class="ql-image mr-2">Image</button>

                                <button class="ql-clean mr-2">clean</button>
                              </div>

                            </quill-editor>
                            
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>

                        </b-card>
                    </b-tab>
                </b-tabs>

                <b-row>
                    <b-col md="12" class="text-center">
                    <b-button
                        variant="primary"
                        type="submit"
                        :disabled="action_press"
                        @click.prevent="validationForm()"
                    >
                        <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                        <div v-else>Salva</div> 
                    </b-button>
                    </b-col>
                </b-row>
              
            </b-form>
          </validation-observer>
        </div>
          
    </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, 
    BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdown, BDropdownItem, BFormSelect } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import {
  required, email, digits, alphaDash, length, password,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs, 
    BTab, 
    BRow,
    BCol,
    BCard, 
    BSpinner, 
    BCardText, 
    BButton, 
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormSelect,

    quillEditor,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,     
  },
  data() {
    return {
      campiform: {
        nome: '',
        id_prod_stripe: '',
        descrizione: '',
        modulo_ordine: '',
        termini_e_condizioni: '',
        clausole_vessatorie: '',
        privacy_policy: ''
      },
      required,
      email,
      password, 
      alphaDash, 
      digits,
      length,
      options_licenze_stripe: [ ],
      Caricato: false,
      errore_caricamento: false,
      submitted: false,
      action_press: false,
      
      snowOption: {
        modules: {
          toolbar: '#toolbar',
        },
        theme: 'snow',
      },
      snowOption_due: {
        modules: {
          toolbar: '#toolbar_due',
        },
        theme: 'snow',
        placeholder: 'Inserisci il testo ...',
      },
      snowOption_tre: {
        modules: {
          toolbar: '#toolbar_tre',
        },
        theme: 'snow',
        placeholder: 'Inserisci il testo ...',
      },
      snowOption_quattro: {
        modules: {
          toolbar: '#toolbar_quattro',
        },
        theme: 'snow',
        placeholder: 'Inserisci il testo ...',
      },

    }
  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    const categoriesPromise = this.$http.get('api/crm/stripe/product/selectlist')  
    const infoContracttemplatePromise = this.$http.get('api/crm/contracttemplate/card/'+router.currentRoute.params.id_riga)

    Promise.all([categoriesPromise, infoContracttemplatePromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista categorie
        this.options_licenze_stripe = response[0].data.reply

        console.log(this.options_licenze_stripe)

        if(response[1].data.statusCode===200){
            //form dati email template
            console.log(response[1].data.reply.data)
            this.campiform = response[1].data.reply.data

            //fine
            this.Caricato = true;
            this.errore_caricamento = false;
        } else {
            this.Caricato = false;
            this.errore_caricamento = true;
        }

      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
        
    })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //console.log(this.campiform)
          this.action_press = true
          
          this.$http.post('api/crm/contracttemplate/card/'+this.campiform.id, 
            this.campiform
            ).then(response => { 
              //console.log("risposta server --------")
              //console.log(response.data)

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                this.$router.replace('/tools/contrattitemplate')
                .then(() => {
                    this.$swal({
                        icon: 'success',
                        title: 'Contratto template aggiornato correttamente',
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })
                })                    

            } else {
                //risposta negativa (errore sul server)
                if(response.data.reply.message != ''){
                this.$swal({
                    icon: 'warning',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.message,
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
                } else {
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore generico',
                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
                }

                this.action_press = false
            }
            }).catch(e => {
              console.log(e);
            });
          
          
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/quill.scss';

.btn-pacchetti{
  width: 120px;
}
</style>
